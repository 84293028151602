import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import Content from '../Content';
import Link from '../Link';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const { t } = useTranslation();

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <PostContent content={content} />
            {tags && tags.length > 0 && (
              <div style={{ marginTop: `4rem` }}>
                <h4>{t('tags.tags')}</h4>
                <ul className="taglist">
                  {map(tags, tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default BlogPostTemplate;
