import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { withPrefix } from 'gatsby';

import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import BlogPostTemplate from '../components/blog/BlogPostTemplate';

const BlogPost = ({ data, pageContext, location }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = post;
  const { t } = useTranslation();

  return (
    <Layout
      lang={pageContext.lang}
      location={location}
      withMeta
      indexed={frontmatter.indexed}
      canonical={frontmatter.canonical}
      alternate={frontmatter.alternate}
    >
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate={`%s | ${t('blog.blog')}`}>
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.title}`} />
            <meta name={`og:title`} content={frontmatter.title} />
            <meta property="og:url" content="/" />
            <meta name={`og:description`} content={frontmatter.title} />
            {frontmatter.featuredimage && (
              <meta
                property="og:image"
                content={`${withPrefix('/')}img/posts/${
                  frontmatter.featuredimage.name
                }.${frontmatter.featuredimage.extension}`}
              />
            )}
          </Helmet>
        }
        tags={frontmatter.tags}
        title={frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredimage {
          name
          extension
        }
        indexed
        canonical
        alternate
        tags
      }
    }
  }
`;
